<template>
	<div class="Brand">
		
		<div class="Top">
			<div class="Left">
				品牌详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回品牌列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="Brand != null && Brand.Id != 'add'">
					<span>品牌ID</span>
					<em>
						{{Brand.Id}}
					</em>
				</li>
				
				<li>
					<span>品牌名</span>
					<em  v-if="Brand != null">
						<el-input v-model="Brand.Name" style="width: 200px;"></el-input>
					</em>
				</li>
				
				<li>
					<span>品牌LOGO</span>
					<em  v-if="Brand != null">
						<el-upload
						  class="avatar-uploader"
						  action="https://jsonplaceholder.typicode.com/posts/"
						  :show-file-list="false" :on-change="getFile" :limit="1" :auto-upload="false">
						  <img v-if="Brand.Logo" :src="Brand.Logo" class="avatar">
						  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</em>
				</li>
				
				

				<li>
					<span>品牌状态</span>
					<em  v-if="Brand != null">
						<el-select placeholder="设置账号状态" v-model="Brand.Status">
							<el-option label="删除" :value="0" disabled></el-option>
							<el-option label="正常" :value="50"></el-option>
						    <el-option label="禁用" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li>
					<span>推荐星级</span>
					<em  v-if="Brand != null">
						<el-input type="text" v-model="Brand.Star" style="width: 120px;"></el-input>
						<i style="margin-left: 10px;">必须为数字</i>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">分类</span>
					<em  v-if="Brand != null" class="Sel">
						<el-select @change="CategoryOneChange" placeholder="设置一级分类" v-model="Brand.CategoryOne">
								<el-option v-for="cat in CategoryListOne" :label="cat.Name" :value="cat.Id" :key="cat.Id"></el-option>
					    </el-select>
						<i style="display: inline-block;margin-right: 5px;"></i>
						<el-select @change="CategoryTwoChange" v-if="CategoryListTwo.length > 0" placeholder="设置二级分类" v-model="Brand.CategoryTwo">
								<el-option v-for="cat in CategoryListTwo" :label="cat.Name" :value="cat.Id" :key="cat.Id"></el-option>
						</el-select>
						<i style="display: inline-block;margin-right: 5px;"></i>
						<el-select v-if="CategoryListThree.length > 0" placeholder="设置三级分类" v-model="Brand.CategoryThree">
								<el-option v-for="cat in CategoryListThree" :label="cat.Name" :value="cat.Id" :key="cat.Id"></el-option>
						</el-select>
					</em>
				</li>
				
				<li v-if="Brand != null && Brand.Id != 'add'">
					<span>创建时间</span>
					<em>
						{{Brand.CreatedAt}}
					</em>
				</li>
				
				<li v-if="Brand != null && Brand.Id != 'add'">
					<span>最后修改时间</span>
					<em>
						{{Brand.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span></span>
					<em>
						<el-button v-if="Brand != null && Brand.Id != 'add'" @click="UpdateBrand()" type="warning">提交修改</el-button>
						<el-button v-if="Brand != null && Brand.Id == 'add'" @click="UpdateBrand()" type="warning">添加品牌</el-button>
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload} from 'element-ui'
	export default {
	  name: 'BrandInfo',
	  props: {
	  },
	  data() {
	      return {
			  Brand:null,
			  CategoryListOne:[],
			  CategoryListTwo:[],
			  CategoryListThree:[],
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少用户身份ID，页面无法工作')
			return
		}
		if(this.$route.params.Id == 'add'){
			this.InitBrand()
			return
		}
		this.GetBrand(this.$route.params.Id)
	  },
	  methods:{
		  getFile(file, fileList) {
		  				this.getBase64(file.raw).then(res => {
		  					this.uploadPic(res)
		  			    });
		  },
		  getBase64(file) {
		        return new Promise(function(resolve, reject) {
		          let reader = new FileReader();
		          let imgResult = "";
		          reader.readAsDataURL(file);
		          reader.onload = function() {
		            imgResult = reader.result;
		          };
		          reader.onerror = function(error) {
		            reject(error);
		          };
		          reader.onloadend = function() {
		            resolve(imgResult);
		          };
		        });
		      },
		  			  uploadPic(_b64){
		  				  let that = this
		  				  let data = {
		  				  	Service:'File',
		  				  	Class: 'File',
		  				  	Action: 'Base64Upload',
		  				  	Base64:_b64
		  				  }
		  				  this.$post(that.$store.getters.getApiHost,data)
		  				  .then((res) => {
		  				  	
		  				  	if(res.ErrorId != 0){
		  				  		that.$message(res.Msg)
		  				  		return
		  				  	}
		  				  	
		  				  	that.Brand.Logo = res.Data.Url
		  				  	
		  				  })
		  				  .catch(function (response) {
		  				  	that.DialogMsg = '网络请求错误'
		  				  })
		  			  },
		  InitBrand(){
			this.Brand = {
				Id:'add',
				Name:'',
				Status:50,
				Logo:'',
				Star:50,
				CategoryOne:'',
				CategoryTwo:'',
				CategoryThree:'',
			}
			this.GetCategoryList(1,"")
		  },
		    GetBrand(_id){
		  		let that = this
		  		let data = {Service:'Brand',Class: 'Brand',Action: 'Get',Id:_id,}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
		  			that.Brand = res.Data
		  			that.GetCategoryList(1,"")
		  		})
		    },
			UpdateBrand(){
				let that = this
				let data = {Service:'Brand',Class: 'Brand',Action: 'Update',Id:that.Brand.Id,Status:that.Brand.Status,Name:that.Brand.Name,Logo:that.Brand.Logo,CategoryOne:that.Brand.CategoryOne,CategoryTwo:that.Brand.CategoryTwo,CategoryThree:that.Brand.CategoryThree,Star:that.Brand.Star}
				if(that.Brand.Id == 'add'){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					that.$message(res.Msg)
					if(res.ErrorId == 0){
						that.$Jump('/do/brand/brand_list')
					}
				})
			},
			CategoryOneChange(_categoryOne){
				this.Brand.CategoryTwo = ""
				this.CategoryListTwo = []
				this.Brand.CategoryThree = ""
				this.CategoryThree = []
				//读取当前一级分类下的所有二级分类
				this.GetCategoryList(2,_categoryOne)
			},
			CategoryTwoChange(_categoryTwo){
				this.Brand.CategoryThree = ""
				this.CategoryThree = []
				//读取当前一级分类下的所有二级分类
				this.GetCategoryList(3,_categoryTwo,true)
			},
			GetCategoryList(_level,_fatherId){
					let that = this
					let data = {
						Service:'Goods',
						Class: 'GoodsCategory',
						Action: 'List',
						Level:_level,
						Page:1,
						PageSize:200,
					}
					this.$post(that.$store.getters.getApiHost,data)
					.then((res) => {
						
						if(res.ErrorId != 0){
							that.$message(res.Msg)
							return
						}
						if(_level == 1){
							that.CategoryListOne = res.Data.CategoryList
						}else if(_level == 2){
							that.CategoryListTwo = res.Data.CategoryList
						}else if(_level == 3){
							that.CategoryListThree = res.Data.CategoryList
						}
						
						//页面初始化分类提取
						if(_level == 1 && that.Brand.CategoryTwo  != ''){
							that.GetCategoryList(2,that.Brand.CategoryOne)
						}
						if(_level == 2 && that.Brand.CategoryThree != ''){
							that.GetCategoryList(3,that.CategoryTwo)
						}
						
					})
					.catch(function (response) {
						that.$message('网络请求错误')
					})
			}
	  }
	}
</script>

<style scoped>
.Brand{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Brand .Top{
	display: flex;
	align-items: center;
}
.Brand .Top .Left{
	
}
.Brand .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Brand .List{
	margin-top: 20px;	
}
.Brand .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Brand .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Brand .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.Brand .List .One li  em{
	flex: 1;
}
.Brand .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }
</style>
